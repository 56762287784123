import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/client/components/render-from-template-context.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/shared/lib/app-router-context.shared-runtime.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/shared/lib/hooks-client-context.shared-runtime.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/shared/lib/loadable-context.shared-runtime.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/shared/lib/server-inserted-html.shared-runtime.js");
